<template>
    <div class="d-flex flex-wrap my-2">
        <div class="col-12 d-flex justify-content-center">
            <span class="text-primary"> Días transcurridos {{ daysAgo }} </span>
        </div>
        <div class="offset-md-2 col-md-4 col-12 mt-1">
            <label class="" for="begin">Fecha inicial</label>
            <vs-input
                id="begin"
                v-model="begin"
                type="date"
                class="w-100 mw-100 border-custom"
                name="trip-begin"
                min="2020-01-01"
                :max="dateMax"
                @input="changeInput"
            />
        </div>
        <div class="col-md-4 col-12 mt-1">
            <label class="" for="end">Fecha final</label>
            <vs-input
                id="end"
                v-model="end"
                type="date"
                class="w-100 mw-100 border-custom"
                name="trip-end"
                min="2020-01-01"
                :max="dateMax"
                @input="changeInput"
            >
            </vs-input>
        </div>
    </div>
</template>

<script>
import { DateTime } from "luxon";

export default {
    name: "DatesRangeFilter",
    model: {
        prop: "itemsCurrent",
        event: "change"
    },
    props: {
        itemsCurrent: {
            type: Object,
            required: false,
            default: () => ({
                end: DateTime.now().toFormat("yyyy-MM-dd"),
                begin: DateTime.now().plus({ days: -1 }).toFormat("yyyy-MM-dd")
            })
        }
    },
    data: () => ({
        dateMax: DateTime.now().toFormat("yyyy-MM-dd"),
        end: DateTime.now().toFormat("yyyy-MM-dd"),
        begin: DateTime.now().plus({ days: -1 }).toFormat("yyyy-MM-dd"),
        daysAgo: 1
    }),
    watch: {
        itemsCurrent(value) {
            this.setInput(value);
        }
    },
    mounted() {
        if (Object.keys(this.itemsCurrent).length === 0) {
            this.changeInput();
        } else {
            this.setInput(this.itemsCurrent);
        }
    },
    methods: {
        changeInput() {
            this.daysAgo = DateTime.fromFormat(this.end, "yyyy-MM-dd").diff(
                DateTime.fromFormat(this.begin, "yyyy-MM-dd"),
                "days"
            ).days;
            this.$emit("change", {
                begin: this.begin,
                end: this.end
            });
        },
        setInput(value) {
            this.begin = String(value.begin);
            this.end = String(value.end);
        }
    }
};
</script>
